import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const KafesTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Kafes Tel İmalatı - Erez Kaynak'
        description='Kafes Tel İmalatı Firmamız siyah ham çelik tel, galvaniz telden yada 304-316 kalite paslanmaz malzeme kullanarak kafes tel imalatı yapmaktadır.Galvaniz yada siyah ham punta kaynaklı hasır teller ham malzeme olduğu için sonradan fırın boya yada daldırma boya ile boyanabilir.Tel kalınlığı 2mm den 8mm'
        keywords='kafes tel,kafes tel fiyatları,kafes tel imalatı,hasır tel kullanım alanları,kafes tel kullanım alanları,tel kafes kullanım alanları,paslanmaz kafes tel,kafes telleri,puntalı kafes tel,punta kaynaklı kafes tel,puntalı kafes tel fiyatları,kafes,tel,kafes tel imalatçısı,özel kafes tel,galvaniz kafes tel,kafes tel istanbul,tel kafes,kafes tel,tel kafes fiyatları,kafes tel fiyatları,kafes tel fiyatı'
        url='kafes-tel-imalati'
        imgurl={getImageByName('hasirtel15').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('hasirtel15').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Kafes Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>siyah ham çelik tel, galvaniz telden ya da 304 - 316</strong> kalite paslanmaz malzeme kullanarak{' '}
                <strong>kafes hasır tel</strong> imalatı yapmaktadır.
              </p>
              <p>
                <strong>Galvaniz ya da siyah ham punta kaynaklı hasır teller</strong> ham malzeme olduğu için sonradan fırın boya yada
                daldırma boya ile boyanabilir.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>kafes hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>kafes hasır tel</strong> imalatı
                yapılabilir.
              </p>
              <p>
                15mm katı olan 15x105mm veya 25mm katı olan 50x150mm gibi istediğiniz her ölçüde <strong>kafes hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>kafes hasır tel</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Kafes hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile hasır
                teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
                çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('hasirtel15')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Kafes Tel Kullanım Alanları' />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default KafesTelPage
